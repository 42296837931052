import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

class HomeIndex extends React.Component {
  render() {
    const siteTitle = 'Aoife grey'
    const siteDescription = ''

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main" className="sans-serif  tc vh-100">
          <section id="first" className="dt w-100 pv3 center">
            <div className="w-100 f5 lh-copy ">
              <h2 className="f3 ma0 fw5  white">
                Aoife Grey
              </h2>
              <p className="ma0 white">
                <a href="https://www.linkedin.com/in/aoifegrey/" target="_blank" className="link white underline" rel="noopener noreferrer">Linkedin Profile</a>
              </p>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
